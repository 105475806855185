import {
  clearContractBudgetHistory,
  fetchContractBudgetHistory,
} from 'actions/contracts/contractBudgetActions'
import TableSimple from 'components/common/tables/TableSimple'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

const ContractBudgetHistory = ({ contractId, selected, currency }) => {
  const { contractBudgetHistory } = useSelector(
    (state) => state.contractBudgets
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      fetchContractBudgetHistory(contractId, selected.contract_budget_id)
    )
    return () => {
      dispatch(clearContractBudgetHistory())
    }
  }, [dispatch, contractId, selected])

  const buildTable = (budgetHistory) => {
    const prefix = currency === '%' ? '' : `${currency} `
    const suffix = currency === '%' ? ` ${currency}` : ''

    const tableData = {
      columns: [
        { label: 'Amount', field: 'amount', minimal: 'sm' },
        { label: 'Date', field: 'created_at', minimal: 'sm' },
        { label: 'User', field: 'user', minimal: 'sm' },
      ],
      rows: budgetHistory.map((x) => ({
        ...x,
        amount: `${x.amount < 0 ? '-' : ''}${prefix}${Math.abs(x.amount)}${suffix}`,
      })),
    }

    return <TableSimple tableData={tableData} />
  }
  return (
    <>{contractBudgetHistory.length > 0 && buildTable(contractBudgetHistory)}</>
  )
}

export default ContractBudgetHistory
