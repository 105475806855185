import { contractsFilterParamsAtom, contractsReloadAtom } from './ContractTable'
import Select from 'components/common/forms/Select'
import { useAuth } from 'hooks/useAuth'
import { useAtom, useSetAtom } from 'jotai'
import _ from 'lodash'
import { MDBCol, MDBRow, MDBIcon, MDBBtn } from 'mdbreact'
import React, { useState, useEffect } from 'react'
import { propertiesToMultiSelectOptions } from 'utils/formUtils'

const resetFilterOptions = (items) => {
  return items.map((item) => {
    return { ...item, checked: false }
  })
}

const customPropertiesToOptions = (items) => {
  const customPropertyOptions = {}
  items.forEach((customPropItem) => {
    if (customPropItem.property?.options?.length) {
      customPropertyOptions[`id_${customPropItem.property.id}`] =
        customPropertyOptions[`id_${customPropItem.property.id}`]?.length > 0
          ? [
              ...customPropertyOptions[`id_${customPropItem.property.id}`],
              ...customPropItem.property.options,
            ]
          : customPropItem.property.options
    }
  })
  const customMultiSelectOptions = {}
  Object.keys(customPropertyOptions).forEach((customKey) => {
    const propertyOptionsNoDuplicates = _.uniqBy(
      customPropertyOptions[customKey],
      'id'
    )
    customMultiSelectOptions[customKey] = propertiesToMultiSelectOptions(
      propertyOptionsNoDuplicates,
      true
    )
  })
  return customMultiSelectOptions
}

const ContractFilter = ({
  contractPropertyOptions,
  hasQueryParams,
  viewParams,
  homePageView,
}) => {
  //// HOOKS.
  const auth = useAuth()

  //// GLOBAL STATE.
  const [contractsFilterParams, setContractsFilterParams] = useAtom(
    contractsFilterParamsAtom
  )
  const setReloadContracts = useSetAtom(contractsReloadAtom)

  //// LOCAL STATE.
  const [showMore, setShowMore] = useState(false)
  const [filterOptions, setFilterOptions] = useState({})

  //// EFFECTS.
  // Set the filter options values for each filter
  useEffect(() => {
    let options = {}
    Object.keys(contractPropertyOptions).forEach((prop) => {
      if (prop !== 'custom_properties') {
        options[prop] = propertiesToMultiSelectOptions(
          contractPropertyOptions[prop]
        )
      } else {
        const customProps = customPropertiesToOptions(
          contractPropertyOptions[prop]
        )
        options = { ...options, ...customProps }
      }
    })

    options.current_state = [
      { text: 'Abandoned', value: '3' },
      { text: 'Completed', value: '2' },
      { text: 'Open', value: '1' },
    ]

    options.is_budget = [
      { text: 'Yes', value: '1' },
      { text: 'No', value: '0' },
    ]
    const availableFilterParams = {}
    Object.keys(options).forEach((opt) => {
      availableFilterParams[opt] = []
    })
    setContractsFilterParams(availableFilterParams)
    setFilterOptions(options)
  }, [contractPropertyOptions, setContractsFilterParams])

  // handle view param change
  useEffect(() => {
    if (!_.isEmpty(filterOptions) && !_.isEmpty(viewParams)) {
      setContractsFilterParams(viewParams)
      const updateFilterSelection = {}
      Object.keys(viewParams).forEach((param) => {
        if (filterOptions[param]) {
          updateFilterSelection[param] = filterOptions[param].map((option) => {
            if (viewParams[param].includes(option.value)) {
              return {
                ...option,
                checked: true,
              }
            }
            return {
              ...option,
              checked: false,
            }
          })
        }
      })
      setFilterOptions(updateFilterSelection)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewParams])

  //// FUNCTIONS.
  const handleSelectValue = (e, param) => {
    setContractsFilterParams({
      ...contractsFilterParams,
      [param]: e,
    })
  }

  //// RENDER VARS.
  const hasSublocations = auth?.user?.customer?.has_sublocation

  //// RENDER.
  return homePageView ? null : (
    <>
      {/* PRIMARY-FILTERS */}
      <MDBRow className="form">
        <MDBCol md="3">
          <Select
            id="multi__select__studies"
            options={filterOptions.study_id}
            getValue={(e) => handleSelectValue(e, 'study_id')}
            label="Sponsor Study Name"
            defaultOption="Choose A Sponsor Study Name"
            multiple
            search
          />
        </MDBCol>
        <MDBCol md="3">
          <Select
            id="multi__select__site-countries"
            options={filterOptions.primary_site_country_id}
            getValue={(e) => handleSelectValue(e, 'primary_site_country_id')}
            label="Country"
            defaultOption="Choose A Country"
            multiple
            search
          />
        </MDBCol>
        <MDBCol md="3">
          <Select
            id="multi__select__sites"
            options={filterOptions.primary_site_id}
            getValue={(e) => handleSelectValue(e, 'primary_site_id')}
            label="Site"
            defaultOption="Choose A Site"
            multiple
            search
          />
        </MDBCol>
        <MDBCol md="3">
          <Select
            id="multi__select__primary-investigator"
            options={filterOptions.primary_investigator_id}
            getValue={(e) => handleSelectValue(e, 'primary_investigator_id')}
            label="Primary Investigator"
            defaultOption="Choose A Primary Investigator"
            multiple
            search
          />
        </MDBCol>
      </MDBRow>
      {showMore && (
        <>
          <MDBRow className="form">
            {/* SECONDARY-FILTERS */}
            <MDBCol md="3">
              <Select
                id="multi__select__contract-types"
                options={filterOptions.contract_type_id}
                getValue={(e) => handleSelectValue(e, 'contract_type_id')}
                label="Contract Type"
                defaultOption="Choose A Contract Type"
                multiple
                search
              />
            </MDBCol>
            <MDBCol md="3">
              <Select
                id="multi__select__contract-subtypes"
                options={filterOptions.contract_subtype_id}
                getValue={(e) => handleSelectValue(e, 'contract_subtype_id')}
                label="Contract Subtype"
                defaultOption="Choose A Contract Subtype"
                multiple
                search
              />
            </MDBCol>
            <MDBCol md="3">
              <Select
                id="multi__select__location"
                options={filterOptions.contract_location_id}
                getValue={(e) => handleSelectValue(e, 'contract_location_id')}
                label="Location"
                defaultOption="Choose A Location"
                multiple
                search
              />
            </MDBCol>
            {hasSublocations && (
              <MDBCol md="3">
                <Select
                  id="multi__select__sublocation"
                  options={filterOptions.contract_sublocation_id}
                  getValue={(e) =>
                    handleSelectValue(e, 'contract_sublocation_id')
                  }
                  label="Sublocation"
                  defaultOption="Choose A Sublocation"
                  multiple
                  search
                />
              </MDBCol>
            )}
            <MDBCol md="3">
              <Select
                id="multi__select__status"
                options={filterOptions.contract_status_id}
                getValue={(e) => handleSelectValue(e, 'contract_status_id')}
                label="Status"
                defaultOption="Choose A Status"
                multiple
                search
              />
            </MDBCol>
            <MDBCol md="3">
              <Select
                id="multi__select__contract-state"
                options={filterOptions.current_state}
                label="Contract State"
                defaultOption="Choose A Contract State"
                multiple
                search
                getValue={(e) => {
                  // SHIMIN: update param mapper to decrement select value by one to match API param values, MDB treats 0 as select all for multi selects
                  const params = e.map((item) => {
                    return item === '4' ? '0' : String(Number(item) - 1)
                  })
                  setContractsFilterParams({
                    ...contractsFilterParams,
                    current_state: params,
                  })
                }}
              />
            </MDBCol>
            <MDBCol md="3">
              <Select
                id="multi__select__has-budget"
                options={filterOptions.is_budget}
                label="Has a Budget?"
                defaultOption="Has a Budget?"
                search
                getValue={(e) => {
                  const params = e.map((item) => {
                    return item === '2' ? '0' : item
                  })
                  setContractsFilterParams({
                    ...contractsFilterParams,
                    is_budget: params,
                  })
                }}
              />
            </MDBCol>
            <MDBCol md="3">
              <Select
                id="multi__select__currency"
                options={filterOptions.currency_id}
                getValue={(e) => handleSelectValue(e, 'currency_id')}
                label="Currency"
                defaultOption="Choose A Currency"
                multiple
                search
              />
            </MDBCol>
          </MDBRow>
          {/* DYNAMIC-FILTERS (custom properties) */}
          <MDBRow className="form">
            {Object.keys(filterOptions).map((filterOpt) => {
              if (filterOpt.startsWith('id_')) {
                const optId = filterOpt.replace('id_', '')
                const propertyName =
                  contractPropertyOptions.custom_properties.find(
                    (customProperty) =>
                      customProperty.property.id.toString() === optId
                  )?.property.name ?? 'Custom Property'
                return (
                  <MDBCol md="3" key={filterOpt}>
                    <Select
                      id={`multi__select__${propertyName}`}
                      label={`${propertyName}`}
                      defaultOption={`Choose A ${propertyName}`}
                      multiple
                      search
                      options={filterOptions[filterOpt]}
                      getValue={(e) => {
                        setContractsFilterParams({
                          ...contractsFilterParams,
                          [filterOpt]: e,
                        })
                      }}
                    />
                  </MDBCol>
                )
              } else {
                return ''
              }
            })}
          </MDBRow>
        </>
      )}

      {/* CONTROLS */}
      <MDBRow className="form contract-filter-fix-bottom">
        <MDBCol md="6">
          {/* SHOW-MORE */}
          <MDBBtn
            size="sm"
            color="primary"
            onClick={() => {
              setShowMore(!showMore)
            }}
          >
            <MDBIcon className="mr-1" icon={showMore ? 'minus' : 'plus'} />
            Show {showMore ? 'Less' : 'More'} Filters
          </MDBBtn>

          {/* RESET FILTERS */}
          <MDBBtn
            disabled={hasQueryParams}
            size="sm"
            color="primary"
            onClick={() => {
              // reset filter options
              const resetOptions = {}
              Object.keys(filterOptions).forEach((opt) => {
                resetOptions[opt] = resetFilterOptions(filterOptions[opt])
              })
              setFilterOptions(resetOptions)

              // reset filter params
              const availableFilterParams = {}
              Object.keys(contractsFilterParams).forEach((opt) => {
                availableFilterParams[opt] = []
              })
              setContractsFilterParams(availableFilterParams)
              setReloadContracts(true)
            }}
          >
            <MDBIcon className="mr-1" icon="redo" />
            Reset Filters
          </MDBBtn>

          {/* REFRESH */}
          <MDBBtn
            onClick={() => {
              setReloadContracts(true)
            }}
            size="sm"
            color="primary"
          >
            Refresh Contracts
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </>
  )
}

export default ContractFilter
