import ContractActivityCreate from './ContractActivityCreate'
import ContractActivityDelete from './ContractActivityDelete'
import ContractActivityUpdate from './ContractActivityUpdate'
import { fetchContractActivities } from 'actions/contracts/contractActivityActions'
import EmptyList from 'components/common/EmptyList'
import Modal from 'components/common/Modal'
import Section from 'components/common/Section'
import Table from 'components/common/tables/Table'
import { useAuth } from 'hooks/useAuth'
import useModal from 'hooks/useModal'
import _ from 'lodash'
import { MDBIcon } from 'mdbreact'
import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isUserNotReadOnlyForContracts } from 'utils/authUtils'
import { fetchDocumentAndDownload } from 'utils/downloadUtils'

const ContractActivity = ({ contract }) => {
  //// HOOKS.
  const dispatch = useDispatch()
  const { user } = useAuth() ?? {}
  const { user_level, is_admin } = user ?? {}
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    footer,
    setFooter,
    actions,
    setActions,
    selected,
    setSelected,
    params,
    setParams,
    setModalState,
  } = useModal()

  //// GLOBAL STATE.
  const { activitiesAvailable, lastAction } = useSelector(
    (state) => state.contractActivities
  )

  //// LOCAL STATE.
  const [tableData, setTableData] = useState({ columns: [] })

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    dispatch(fetchContractActivities(contract.id))
  }, [dispatch, contract.id])

  useEffect(() => {
    setTableData({
      columns: [
        {
          label: 'Date Occurred (UTC)',
          field: 'activity_occurred_at',
          sort: 'desc',
          date: true,
          minimal: 'lg',
        },
        {
          label: 'Status',
          field: 'contract_status.name',
          sort: 'asc',
        },
        {
          label: 'Location',
          field: 'contract_location.name',
          sort: 'asc',
        },
        ...(user?.customer?.has_sublocation
          ? [
              {
                label: 'Sublocation',
                field: 'contract_sublocation.name',
                sort: 'asc',
              },
            ]
          : []),
        {
          label: 'Comment',
          field: 'comment',
          sort: 'asc',
        },
        {
          label: '',
          field: 'buttons',
          sort: 'disabled',
          minimal: 'lg',
        },
      ],
    })
  }, [user?.customer?.has_sublocation])

  //// RENDER VARS & HELPERS.
  const toggleModal = useCallback(
    (e, type, item) => {
      if (e) e.preventDefault()

      if (type === 'new') {
        setHeaderText('New Activity')
        setFooter(true)
        setActions([
          {
            text: 'Submit',
            color: 'primary',
            additionalProps: {
              form: formId,
              type: 'submit',
            },
          },
        ])
      } else if (type === 'edit') {
        setHeaderText('Edit Activity')
        setFooter(true)
        setActions([
          {
            text: 'Update',
            color: 'primary',
            additionalProps: {
              form: formId,
              type: 'submit',
            },
          },
        ])
        setSelected(item)
      } else if (type === 'delete') {
        setHeaderText('Delete Activity')
        setFooter(true)
        setActions([
          {
            text: 'Delete',
            color: 'danger',
            additionalProps: {
              form: formId,
              type: 'submit',
            },
          },
        ])
        setSelected(item)
      }
      if (type) {
        setModalState(true)
      } else {
        setModalState(false)
      }
    },
    [formId, setActions, setFooter, setHeaderText, setModalState, setSelected]
  )

  const handleDocumentDownload = useCallback(
    (e, item) => {
      e.preventDefault()
      const documentToFetch = {
        contract_id: contract.id,
        contract_document_id: item.contract_document_id,
      }
      fetchDocumentAndDownload(documentToFetch)
    },
    [contract.id]
  )

  //// RENDER.
  return tableData.columns.length === 0 ? null : (
    <>
      <Section
        header="Contract Activities"
        description={
          contract.current_state !== 0
            ? `Contract activities cannot be updated or added when the contract is ${contract.current_state_text.toLowerCase()}`
            : 'View, add, and edit contract activity'
        }
        menu={
          isUserNotReadOnlyForContracts(user_level)
            ? {
                order: 2,
                show: contract.current_state === 0 ? true : false,
                linkItems: [
                  {
                    name: (
                      <>
                        <MDBIcon icon="fas fa-plus" /> Add Activity
                      </>
                    ),
                    url: `/contracts/${contract.id}/new/activity`,
                    exact: true,
                    additionalProps: {
                      active: true,
                      onClick: (e) => toggleModal(e, 'new'),
                      className: 'btn-sm',
                    },
                  },
                ],
              }
            : null
        }
      >
        {_.size(activitiesAvailable) ? (
          <Table
            section="Contract Activity"
            tableData={tableData}
            setTableData={setTableData}
            rowData={activitiesAvailable}
            sort={['activity_occurred_at', 'desc']}
            toggleModal={toggleModal}
            downloadFile={handleDocumentDownload}
            updatedState={lastAction}
            downloadableCSV
            editButton={
              contract.current_state === 0 &&
              isUserNotReadOnlyForContracts(user_level)
            }
            deleteButton={is_admin}
            downloadButton
          />
        ) : _.isEmpty(activitiesAvailable) && contract.current_state === 0 ? (
          <EmptyList
            name="activity"
            namePlural="activities"
            userRole={user_level}
            createElement={
              <span className="link" onClick={(e) => toggleModal(e, 'new')}>
                Create
              </span>
            }
          />
        ) : _.isEmpty(activitiesAvailable) ? (
          <p>No contract activities were added to this contract while open.</p>
        ) : (
          void 0
        )}
      </Section>
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        headerText={headerText}
        includeFooter={footer}
        actionButtons={actions}
      >
        {headerText === 'New Activity' ? (
          <ContractActivityCreate
            formId={formId}
            contractId={contract.id}
            params={params}
            setParams={setParams}
            closeModal={() => setModalState(false)}
          />
        ) : headerText === 'Edit Activity' ? (
          <ContractActivityUpdate
            formId={formId}
            contractId={contract.id}
            params={params}
            setParams={setParams}
            selected={selected}
            setSelected={setSelected}
            closeModal={() => setModalState(false)}
          />
        ) : headerText === 'Delete Activity' ? (
          <ContractActivityDelete
            formId={formId}
            selected={selected}
            closeModal={() => setModalState(false)}
          />
        ) : (
          void 0
        )}
      </Modal>
    </>
  )
}

export default ContractActivity
