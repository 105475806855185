import 'assets/scss/admin.scss'
import AdminRoutes from 'components/routes/AdminRoutes'
import { useAuth } from 'hooks/useAuth'
import {
  MDBCard,
  MDBCardHeader,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBContainer,
} from 'mdbreact'
import React from 'react'

const AdminHome = () => {
  const auth = useAuth()
  const hasSiteReadiness = auth.user?.customer?.has_site_readiness
  const hasSublocations = auth.user?.customer?.has_sublocation

  return (
    !!auth.user && (
      <MDBContainer fluid>
        <MDBCard>
          <MDBCardHeader className="admin-header" color="secondary-color">
            <MDBNav>
              <MDBNavItem>
                <MDBNavLink exact to="/admin/users">
                  Users
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    Budget
                  </MDBDropdownToggle>
                  <MDBDropdownMenu
                    color="secondary"
                    className="secondary-color"
                  >
                    <MDBDropdownItem tag="div">
                      <MDBNavLink exact to="/admin/budget">
                        Budget Visits
                      </MDBNavLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem divider />
                    <MDBDropdownItem tag="div">
                      <MDBNavLink to="/admin/budget/studies">
                        Study Budget Visits
                      </MDBNavLink>
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    Contracts
                  </MDBDropdownToggle>
                  <MDBDropdownMenu
                    color="secondary"
                    className="secondary-color"
                  >
                    <MDBDropdownItem tag="div">
                      <MDBNavLink to="/admin/contracts/types">Types</MDBNavLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem divider />
                    <MDBDropdownItem tag="div">
                      <MDBNavLink to="/admin/contracts/subtypes">
                        Subtypes
                      </MDBNavLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem divider />
                    <MDBDropdownItem tag="div">
                      <MDBNavLink to="/admin/contracts/statuses">
                        Statuses
                      </MDBNavLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem divider />
                    <MDBDropdownItem tag="div">
                      <MDBNavLink to="/admin/contracts/locations">
                        Locations
                      </MDBNavLink>
                    </MDBDropdownItem>
                    {!!hasSublocations && (
                      <>
                        <MDBDropdownItem divider />
                        <MDBDropdownItem tag="div">
                          <MDBNavLink to="/admin/contracts/sublocations">
                            Sublocations
                          </MDBNavLink>
                        </MDBDropdownItem>
                      </>
                    )}
                    <MDBDropdownItem divider />
                    <MDBDropdownItem tag="div">
                      <MDBNavLink to="/admin/contracts/document-types">
                        Document Types
                      </MDBNavLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem divider />
                    <MDBDropdownItem tag="div">
                      <MDBNavLink to="/admin/contracts/activity-alerts">
                        Activity Alerts
                      </MDBNavLink>
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
              {!!hasSiteReadiness && (
                <MDBNavItem>
                  <MDBDropdown>
                    <MDBDropdownToggle nav caret>
                      Site Readiness
                    </MDBDropdownToggle>
                    <MDBDropdownMenu
                      color="secondary"
                      className="secondary-color"
                    >
                      <MDBDropdownItem tag="div">
                        <MDBNavLink to="/admin/sr-documents/types">
                          Types
                        </MDBNavLink>
                      </MDBDropdownItem>
                      <MDBDropdownItem divider />
                      <MDBDropdownItem tag="div">
                        <MDBNavLink to="/admin/sr-documents/subtypes">
                          Subtypes
                        </MDBNavLink>
                      </MDBDropdownItem>
                      <MDBDropdownItem divider />
                      <MDBDropdownItem tag="div">
                        <MDBNavLink to="/admin/sr-documents/statuses">
                          Statuses
                        </MDBNavLink>
                      </MDBDropdownItem>
                      <MDBDropdownItem divider />
                      <MDBDropdownItem tag="div">
                        <MDBNavLink to="/admin/sr-documents/locations">
                          Locations
                        </MDBNavLink>
                      </MDBDropdownItem>
                      <MDBDropdownItem divider />
                      <MDBDropdownItem tag="div">
                        <MDBNavLink to="/admin/sr-documents/document-types">
                          File Types
                        </MDBNavLink>
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
              )}

              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    Custom Properties
                  </MDBDropdownToggle>
                  <MDBDropdownMenu
                    color="secondary"
                    className="secondary-color"
                  >
                    <MDBDropdownItem tag="div">
                      <MDBNavLink exact to="/admin/properties">
                        Properties
                      </MDBNavLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem divider />
                    <MDBDropdownItem tag="div">
                      <MDBNavLink to="/admin/properties/contracts">
                        Contract Properties
                      </MDBNavLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem divider />
                    <MDBDropdownItem tag="div">
                      <MDBNavLink to="/admin/properties/studies">
                        Study Properties
                      </MDBNavLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem divider />
                    <MDBDropdownItem tag="div">
                      <MDBNavLink to="/admin/properties/contacts">
                        Contact Properties
                      </MDBNavLink>
                    </MDBDropdownItem>
                    {!!hasSiteReadiness && (
                      <>
                        <MDBDropdownItem divider />
                        <MDBDropdownItem tag="div">
                          <MDBNavLink to="/admin/properties/sr-documents">
                            Site Readiness Properties
                          </MDBNavLink>
                        </MDBDropdownItem>
                      </>
                    )}
                    <MDBDropdownItem divider />
                    <MDBDropdownItem tag="div">
                      <MDBNavLink to="/admin/properties/sites">
                        Site Properties
                      </MDBNavLink>
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink exact to="/admin/invoice-milestones">
                  Invoice Milestones
                </MDBNavLink>
              </MDBNavItem>
            </MDBNav>
          </MDBCardHeader>
          <AdminRoutes />
        </MDBCard>
      </MDBContainer>
    )
  )
}

export default AdminHome
